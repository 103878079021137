import { createHash, createDecipheriv } from 'crypto';

export const encryptPin = (pin: string) => {
  return createHash('sha1').update(pin).digest('hex').toString();
};

// to update...
export const decryptPin = (encryptedText: string) => {
  const decipher = createDecipheriv('des-ede', 'zAL7X5AVRm8l4Ifs', '');
  let decrypted = decipher.update(encryptedText, 'base64', 'utf8');
  decrypted += decipher.final('utf8');
  return decrypted;
};

export const encryptPassword = (password: string) => {
  return createHash('sha1').update(password).digest('hex').toString();
};
