'use client';
import { Button } from '@/components/atom/button';
import { Input } from '@/components/atom/input';
import { PasswordInput } from '@/components/atom/password-input';
import { GoogleIcon } from '@/components/icons';
import { SignInFormModel } from '@/lib/models';
import { signInValidationSchema } from '@/lib/schemas';
import {
  deleteOnboardPayload,
  loginService,
  SignInWithGoogle,
} from '@/server/actions/auth.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loader2Icon } from 'lucide-react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useToast } from '@/hooks/use-toast';
import React from 'react';
import { useForm } from 'react-hook-form';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import useAuthStore from '@/hooks/store/use-auth-store';
import { cn } from '@/lib/utils';
import { encryptPassword } from '@/lib/encrypt';
import { useQueryClient } from '@tanstack/react-query';
import { useMixpanel } from '@/providers/MixPanelProvider';

const SignInForm = ({
  className,
  onSigninComplete,
  title,
  preventUseEffect = false,
}: {
  className?: string;
  onSigninComplete?: () => void;
  title?: string;
  preventUseEffect?: boolean;
}) => {
  const { trackEvent } = useMixpanel();
  const queryClient = useQueryClient();
  const params = useSearchParams();
  const callbackUrl = params.get('callback');
  const router = useRouter();
  const { toast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const { setOnboardingSteps } = useAuthStore();

  const form = useForm<SignInFormModel>({
    resolver: yupResolver(signInValidationSchema),
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onChange',
  });

  React.useEffect(() => {
    const resetCookie = async () => {
      try {
        await deleteOnboardPayload();
      } catch (error) {
        console.log('from signin', error);
      }
    };

    if (preventUseEffect === false) resetCookie();
  }, []);

  const onSubmit = async (values: SignInFormModel) => {
    trackEvent('submit_form', { title: 'Submit sign in form to initiate sign in' });
    setLoading(true);
    try {
      const res = await loginService({
        username: values.username,
        // password: values.password,
        password: encryptPassword(values.password),
      });

      if (res.success) {
        trackEvent('sign_in_success', { title: `Sign in as ${values.username} successful` });
        if (onSigninComplete) {
          onSigninComplete();
          queryClient.resetQueries();
        } else {
          router.push(callbackUrl ?? '/feeds');
        }
      } else {
        trackEvent('sign_in_failed', { title: `Attempted sign in as ${values.username} failed` });
        toast({
          variant: 'destructive',
          description: res.message ?? 'Unable to sign you in. Please try again.',
        });
      }
    } catch (error) {
      console.log({ error });
      trackEvent('sign_in_success', { title: `Attempted sign in as ${values.username} failed` });
      toast({
        variant: 'destructive',
        description: 'Email address or password is incorrect.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn(
          'bg-white px-4 py-6 md:px-[60px] md:py-10 rounded-none md:rounded-xl shadow-fab h-full flex-grow w-full overflow-auto max-h-full flex flex-col items-center justify-between gap-10 md:gap-4 md:h-fit max-w-[440px]',
          className
        )}
      >
        <div className="w-full">
          <div className="space-y-1 mb-[18px] md:mb-10">
            <h1 className="text-2xl font-medium text-purple-brand3">
              {title ?? 'Sign In to Fusion'}
            </h1>
          </div>

          <div className="grid grid-cols-1 gap-6 w-full mb-6">
            <Button
              variant="outline"
              className="w-full gap-2"
              type="button"
              onClick={() => {
                setOnboardingSteps(true);
                SignInWithGoogle();
                trackEvent('button_clicked', {
                  title: 'Sign in with google button clicked',
                });
              }}
            >
              <GoogleIcon />
              Sign in with Google
            </Button>
            <p className="text-xs text-black-brand text-center">Or enter your details</p>
          </div>

          <div className="w-full mb-4">
            <label className="text-sm font-medium" htmlFor="username">
              Email Address
            </label>
            <Input
              {...form.register('username')}
              errorMessage={form.formState.errors.username?.message}
              disabled={loading}
              placeholder="Enter a valid email address"
            />
          </div>

          <div>
            <label htmlFor="password">Password</label>
            <PasswordInput
              {...form.register('password')}
              errorMessage={form.formState.errors.password?.message}
              disabled={loading}
              type="password"
              placeholder="Enter your password"
              hideInnerChecks
            />
          </div>
          <div className="flex justify-end items-center mb-6">
            <Link
              onClick={() =>
                trackEvent('navigate_clicked', {
                  title: 'Navigate to forgot password from sign in form',
                })
              }
              href="/signin?ref=forgot-password"
              className="text-sm hover:underline text-gray-brand"
            >
              Forgot Password
            </Link>
          </div>
        </div>

        <div className="w-full space-y-6 md:space-y-[60px]">
          <Button className="w-full" disabled={loading || !form.formState.isValid}>
            {loading ? (
              <>
                <Loader2Icon size={16} strokeWidth={1.5} className="animate-spin mr-1" /> Please
                wait...
              </>
            ) : (
              'Log In'
            )}
          </Button>

          <div className="text-center mt-2 text-sm md:text-base text-gray-brand font-semibold md:font-normal">
            Don&apos;t have a Fusion account?{' '}
            <Link href="/signup" className="hover:underline text-purple-brand md:font-medium">
              Sign Up
            </Link>
          </div>
        </div>
      </form>
      <ForgotPasswordDialog
        open={params.get('ref') === 'forgot-password'}
        openChange={() => router.replace('/signin')}
      />
    </>
  );
};

export default SignInForm;
