import { getEventItemBySlug } from '@/services/shop.service';
import { useQuery } from '@tanstack/react-query';

const useGetEventItemBySlug = ({ Slug }: { Slug?: string }) => {
  return useQuery({
    queryKey: ['get-event-item-by-slug', { Slug }],
    queryFn: () => getEventItemBySlug({ slug: Slug }),
    enabled: !!Slug,
  });
};

export default useGetEventItemBySlug;
