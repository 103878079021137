export const capitalizeFirstLetter = (word: string) => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return '';
};

export const formatPathname = (input: string): string => {
  // Remove trailing slash if it exists
  if (input.endsWith('/')) {
    input = input.slice(0, -1);
  }

  // Split the string by "/"
  const parts = input.split('/');

  // Get the last two segments of the path
  const lastSegment = parts[parts.length - 1];

  if (!lastSegment.includes(`-`)) return capitalizeFirstLetter(lastSegment);

  const formattedLastSegment = lastSegment
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedLastSegment;
};

export const formatQuantityText = (quantity: string | number, text: string) => {
  const number = typeof quantity === 'string' ? parseFloat(quantity) : quantity;
  if (+number === 1) return `${number} ${text}`;
  return `${number?.toLocaleString()} ${text}s`;
};

export const formatReadableNumber = (quantity: string) => {
  return parseFloat(quantity).toLocaleString();
};
