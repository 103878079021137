import { onboardingSteps } from '@/constants';
import { StepperType, RegisterPayloadType } from '@/types';
import { create } from 'zustand';

type AuthStoreType = {
  currentOnboardStep: number;
  onboardSteps: StepperType[];
  goToNextStep: () => void;
  goToPrevStep: () => void;
  setOnboardingSteps: (v: boolean) => void;
  registerPayload: Partial<RegisterPayloadType> | null;
  setRegisterPayload: (data: Partial<RegisterPayloadType>) => void;
  openSignInModal: boolean;
  setToggleShowSignInModal: () => void;
};

const useAuthStore = create<AuthStoreType>((set) => ({
  openSignInModal: false,
  registerPayload: {},
  currentOnboardStep: 0,
  onboardSteps: onboardingSteps,
  setOnboardingSteps: (isGoogle: boolean) =>
    set((state) => ({
      onboardSteps: isGoogle
        ? [
            {
              name: 'Confirm details',
              path: '/signup/confirm-details',
              step: 1,
            },
            ...state.onboardSteps.slice(1),
          ]
        : [...state.onboardSteps],
    })),
  goToNextStep: () =>
    set((state) => ({
      currentOnboardStep: state.currentOnboardStep + 1,
    })),

  setRegisterPayload: (data) =>
    set((state) => ({
      registerPayload: state.registerPayload ? { ...state.registerPayload, ...data } : { ...data },
    })),

  goToPrevStep: () =>
    set((state) => ({
      currentOnboardStep:
        state.currentOnboardStep >= 0 ? state.currentOnboardStep - 1 : state.currentOnboardStep,
    })),

  setToggleShowSignInModal: () =>
    set((state) => ({
      openSignInModal: !state.openSignInModal,
    })),
}));

export default useAuthStore;
