import React from 'react';
import { create } from 'zustand';

type AppStoreType = {
  showShareModal: boolean;
  shareTitle: React.ReactNode;
  path: string;
  closeShareModal: () => void;
  openShareModal: (v: { title: React.ReactNode; path: string }) => void;
};

const useAppStore = create<AppStoreType>((set) => ({
  showShareModal: false,
  shareTitle: '',
  path: '',
  closeShareModal: () =>
    set({
      showShareModal: false,
      shareTitle: '',
      path: '',
    }),
  openShareModal: ({ title, path }: { title: React.ReactNode; path: string }) =>
    set({
      showShareModal: true,
      shareTitle: title,
      path,
    }),
}));

export default useAppStore;
