import { validateOtp } from '@/server/actions/auth.actions';
import { ValidateOtpInput } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useValidateOtpMutation = (isReset = false) => {
  return useMutation({
    mutationKey: ['VerifyEmailAndPhoneNumber', { reset: isReset }],
    mutationFn: (payload: ValidateOtpInput) => validateOtp(payload),
  });
};

export default useValidateOtpMutation;
