import { useState, useEffect } from 'react';

export function useDeviceType(): 'desktop' | 'tablet' | 'phone' {
  const [deviceType, setDeviceType] = useState<'desktop' | 'tablet' | 'phone'>('desktop'); // Holds the current device type: 'large', 'tablet', or 'phone'

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.matchMedia('(min-width: 1024px)').matches) {
        setDeviceType('desktop');
      } else if (window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches) {
        setDeviceType('tablet');
      } else {
        setDeviceType('phone');
      }
    };

    // Set initial device type
    updateDeviceType();

    // Update device type on window resize
    window.addEventListener('resize', updateDeviceType);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updateDeviceType);
    };
  }, []);

  return deviceType; // Return the current device type as a string
}
