import { ChangePasswordModel } from "@/lib/models";
import { changePassword } from "@/server/actions/auth.actions";
import { useMutation } from "@tanstack/react-query";

const useChangePasswordMutation = () => {
  return useMutation({
    mutationKey: ["ChangePassword"],
    mutationFn: (payload: ChangePasswordModel) => changePassword(payload),
  });
};

export default useChangePasswordMutation;
