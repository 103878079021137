import { EmailModel } from "@/lib/models";
import { startPasswordReset } from "@/server/actions/auth.actions";
import { useMutation } from "@tanstack/react-query";

const useStartResetPasswordMutation = () => {
  return useMutation({
    mutationKey: ["StartResetPassword"],
    mutationFn: (payload: EmailModel) => startPasswordReset(payload),
  });
};

export default useStartResetPasswordMutation;
